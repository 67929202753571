<template>
  <section
      id="get-in-touch"
      class="overflow-hidden"
  >
    <v-row
        class="info white--text"
        no-gutters
    >
      <v-col
          class="hidden-sm-and-down"
          md="6"
      >
        <v-img
            :src="require('@/assets/contact.png')"
            height="100%"
            contain
        />
      </v-col>

      <v-col
          class="pa-5"
          cols="12"
          md="6"
          align-self="center"
      >
        <base-heading class="mb-5">
          {{$t("getInTouch.title")}}
        </base-heading>
        <base-subheading
            v-if="!sent"
        >
          {{$t("getInTouch.text")}}
        </base-subheading>
        <v-sheet
            v-if="!sent"
            color="transparent"
            max-width="600"
        >
          <v-form
              v-model="valid"
          >
            <v-text-field
                color="info"
                :label="$i18n.t('getInTouch.name')"
                v-model="form.name"
                :rules="[v => !!v || $i18n.t('getInTouch.rules.name.required'),
                         v => (v && v.length <= 20) || $i18n.t('getInTouch.rules.name.less')]"
                solo
                flat
            />

            <v-text-field
                color="info"
                :label="$i18n.t('getInTouch.email')"
                v-model="form.email"
                :rules="[
        v => !!v || $i18n.t('getInTouch.rules.email.required'),
        v => /.+@.+\..+/.test(v) || $i18n.t('getInTouch.rules.email.valid'),
      ]"
                solo
                flat
            />

            <v-text-field
                color="info"
                :label="$i18n.t('getInTouch.subject')"
                v-model="form.subject"
                :rules="[v => !!v || $i18n.t('getInTouch.rules.subject.required'),
                          v => (v && v.length <= 20) || $i18n.t('getInTouch.rules.subject.less')]"
                solo
                flat
                required
            />

            <v-textarea
                color="info"
                :label="$i18n.t('getInTouch.message')"
                v-model="form.message"
                :rules="[v => !!v || $i18n.t('getInTouch.rules.text.required'),
                          v => (v && v.length <= 70) || $i18n.t('getInTouch.rules.text.less')]"
                solo
                flat
                required
            />
          </v-form>
          <base-btn
              :disabled="!valid"
              @click="onSubmit"
          >{{ $t("getInTouch.btn") }}</base-btn>
        </v-sheet>
        <v-sheet v-if="sent">
          <p class="text-h6  text-md-3 font-weight-medium">
            {{$t("getInTouch.sent")}}
          </p>
        </v-sheet>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import querystring from 'querystring'
import axios from "axios"

export default {
  name: 'GetInTouch',
  data: () => ({
    sent:false,
    valid: false,
    form: {
      name: '',
      email: '',
      subject: '',
      message:''
    }
  }),
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    async onSubmit(e) {
      try {
        e.preventDefault()
        await axios.post("https://www.gerhardherrera.de/mail.php", querystring.stringify(this.form))
        this.sent = true
      }
      catch (e) {
        console.log(e)
      }
    }
  }
}
// TODO Validate Form
// TODO CORS Policy
</script>
